/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PreferenceResource } from '../models/PreferenceResource';
import type { StorePreferencesRequest } from '../models/StorePreferencesRequest';
import type { UpdatePreferencesRequest } from '../models/UpdatePreferencesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PreferencesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display all user preferences.
     * @returns any OK
     * @throws ApiError
     */
    public getPreferences({
        filter,
    }: {
        /**
         *
         * Filtered fields:
         * | Field | Field Type | Description |
         * |--|--|--|
         * |name|text|Search by  name of the preference.|
         *
         * See **Filters** above for more details about using filters.
         */
        filter?: string,
    }): CancelablePromise<{
        data: Array<PreferenceResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preferences',
            query: {
                'filter': filter,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create new user preferences.
     * @returns PreferenceResource OK
     * @throws ApiError
     */
    public createPreferences({
        requestBody,
    }: {
        /**
         * Preferences details
         */
        requestBody: StorePreferencesRequest,
    }): CancelablePromise<PreferenceResource> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/preferences',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Update a specific user preference.
     * @returns any OK
     * @throws ApiError
     */
    public updatePreference({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Preferences payload.
         */
        requestBody: UpdatePreferencesRequest,
    }): CancelablePromise<{
        data: PreferenceResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/preferences/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
