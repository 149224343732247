export default {
    bulksms: {
        title: `New Text`,
        to: `To:`,
        send: `Send Now`,
        search: `Search for contacts...`,
        placeholder: `Start typing your text...`,
        linkPhoto: `Link to Photo or File`,
        shortenUrl: `Shorten a URL`,
        charactersRemaining: `%{count} Characters remaining`,
        change: `change`,
        options: {
            sendNow: `Send Now`,
            sendCustom: `Send on custom date and time`,
            sendTomorrow3: `Send tomorrow at 3pm`,
            sendTomorrow9: `Send tomorrow at 9am`,
            sendIn6: `Send in 6 hours`
        },
        instructions: `When linking to a photo or file, Breeze uses a URL shortener to give you more space to type your message`,
        textRemain: `%{count} Texts Remain this Month`,
        searching: `Searching...`,
        searchContacts: `Search for contacts...`,
        noNumber: `No mobile number`,
        alreadyRecipient: `Already a recipient`,
        loadingContacts: `Loading contacts...`
    },
    addFamilyMember: {
        title: `Add Family Member`,
        options: {
            newPerson: {
                title: `New Person`,
                description: `Add a new person to the database as a member of this family.`
            },
            existingPerson: {
                title: `Existing Person`,
                description: `Select a person already in the database to associate as a family member.`
            }
        },
        cancel: `Cancel`
    },
    displayFields: {
        title: `Display Fields`,
        instructions: `Select the profile fields you'd like to include when adding new people. These preferences will be saved for your user. In order to add additional field options, you will need to add a custom field in the Profile Fields section.`
    }
}
